import React from 'react'
import { useQuery } from '@apollo/client'
import Dialog from 'components/dialog'
import Box from '@material-ui/core/Box'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import { GET_GROUP_EVENT_BY_ID } from './queries'
import LinearProgress from '@material-ui/core/LinearProgress'
import GraduateForm from './graduate-form'

const GraduateModal = ({ trigger, event }) => {
  const { data, error, loading, refetch } = useQuery(GET_GROUP_EVENT_BY_ID, {
    variables: {
      id: event.id,
    },
  })

  if (loading) return <LinearProgress color="primary" />
  if (error) return <p>Error</p>

  return (
    <Dialog trigger={trigger} showCloseButton>
      {({ closeDialog }) => {
        return (
          <Box>
            <DialogTitle>Update Graduate Status</DialogTitle>
            <DialogContent dividers>
              <Typography variant="body1" color="textSecondary">
                By marking the golfer as a graduate, you are confirming that the
                golfers attended 80% of their PGA HOPE sessions
              </Typography>
              <GraduateForm
                event={event}
                enrollments={data?.groupEvents[0]?.enrollments}
                closeDialog={closeDialog}
                refetch={refetch}
              />
            </DialogContent>
          </Box>
        )
      }}
    </Dialog>
  )
}

export default GraduateModal
