/* eslint-disable camelcase */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FacilitiesAlgolia from 'components/coaches-profile/profile-components/FacilitiesAlgolia'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import usStates from 'utils/usStates'
import FacilityDisplay from './FacilityDisplay'
import useStyles, {
  ErrorMsg,
  FacilityImage,
  HelperMsg,
  SectionTitle,
} from 'components/coaches-profile/profile-stepper.styles'
import TextFieldCounter from 'components/text-field-counter'
import { CLOUDINARY_CLOUDNAME, CLOUDINARY_UPLOAD_PRESET_LARGE } from 'env'
import { handleImageUpload } from 'lib/cloudinary/uploadImage'

const photosMsg = 'Add one photo per facility.'

const uploadOptions = {
  cloudName: CLOUDINARY_CLOUDNAME,
  upload_preset: CLOUDINARY_UPLOAD_PRESET_LARGE,
  sources: ['local', 'camera'],
  cropping: true,
  croppingAspectRatio: 1.7,
  showSkipCropButton: false,
  resourceType: 'image',
  multiple: false,
  folder: 'dot-com-images',
  showPoweredBy: false,
}

const CoachProfileEditFacilities = props => {
  const {
    formPrefix,
    isPrimary,
    deleteMe,
    errors,
    setFieldValue,
    register,
    control,
    watch,
    facilityIndex,
    facility,
  } = props

  const { getValues } = useFormContext()
  const allValues = getValues()
  const facilityValuesLocal =
    (allValues?.facilities && allValues.facilities[facilityIndex]) || {}

  const facilityValues =
    (!isEmpty(facilityValuesLocal) && facilityValuesLocal) || facility

  const styles = useStyles()
  const formField = name => `${formPrefix}.${name}`
  watch([formField('isPrivate')])

  const [uploadError, setUploadError] = useState()
  const [isFacilitySelected, setIsFacilitySelected] = useState(
    !!facilityValues?.pgaFacilityId,
  )

  const setPGAFacility = hit => {
    const {
      name: facilityName,
      address1: street,
      city,
      state,
      zip: zipCode,
      phone: phoneNumber,
      website,
      operations_type_label,
      facility_id: pgaFacilityId,
    } = hit

    setFieldValue(`${formPrefix}`, {
      ...facilityValues,
      facilityName,
      street,
      city,
      state,
      zipCode,
      phoneNumber,
      website:
        website && !/^https?:\/\//i.test(website)
          ? `http://${website}`
          : website,
      pgaFacilityId,
      isPrivate:
        operations_type_label === 'Private Equity' ||
        operations_type_label === 'Private Non-Equity',
      customData: {
        ...facilityValues.customData,
        offersCoachingAtPrivateClub: false,
      },
    })
    setIsFacilitySelected(!!pgaFacilityId)
  }

  const clearPGAFacilitySelection = () => {
    setIsFacilitySelected(false)
    setFieldValue(`${formPrefix}`, {
      ...facilityValues,
      facilityId: null,
      facilityName: '',
      street: '',
      city: '',
      state: '',
      zipCode: '',
      phoneNumber: '',
      website: '',
      pgaFacilityId: null,
      isPrivate: false,
      customData: {
        ...facilityValues.customData,
        offersCoachingAtPrivateClub: false,
      },
    })
  }

  const title = (
    <Typography
      variant="h6"
      style={{
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'end',
        color: 'black',
      }}
    >
      {!isPrimary && (
        <Button
          style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
          onClick={deleteMe}
        >
          Delete
        </Button>
      )}
    </Typography>
  )

  const facilityFormFields = (
    <Box display={isFacilitySelected ? 'none' : 'block'} maxWidth="600px">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            name={formField('facilityName')}
            control={control}
            required
            render={({ onChange, onBlur, value }, _) => (
              <FacilitiesAlgolia
                name={formField('facilityName')}
                label="Facility Name *"
                onSelect={setPGAFacility}
                value={value}
                onBlur={onBlur}
                error={!!errors.facilityName}
                helperText={errors?.facilityName?.message}
                onChange={onChange}
                InputProps={{ required: true }}
              />
            )}
          />
        </Grid>
        <input
          name={formField('pgaFacilityId')}
          type="hidden"
          ref={register()}
          value={facilityValues.pgaFacilityId || ''}
        />
        <input
          name={formField('customData.facilityRank')}
          type="hidden"
          ref={register()}
          value={facilityIndex + 1}
        />
        <Grid item xs={12}>
          <TextField
            name={formField('street')}
            label="Address"
            type="text"
            variant="outlined"
            defaultValue={facilityValues.street || ''}
            inputRef={register()}
            className="text-input"
            error={!!errors.street}
            helperText={errors?.street?.message}
            required
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            name={formField('city')}
            label="City"
            type="text"
            variant="outlined"
            defaultValue={facilityValues.city || ''}
            inputRef={register()}
            className="text-input"
            error={!!errors?.city}
            helperText={errors?.city?.message}
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl
            error={!!errors.state}
            className={`text-input ${errors.state && ' error'}`}
          >
            <Controller
              name={formField('state')}
              control={control}
              render={({ value, onChange }) => (
                <Autocomplete
                  name={formField('state')}
                  disableClearable
                  required
                  value={value || ''}
                  options={usStates}
                  onChange={(e, value) => onChange(value)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="State"
                      variant="outlined"
                      error={!!errors.state}
                      helperText={errors?.state?.message}
                      required
                    />
                  )}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name={formField('zipCode')}
            label="Zip"
            type="text"
            variant="outlined"
            defaultValue={facilityValues.zipCode || ''}
            inputRef={register()}
            className="text-input"
            error={!!errors.zipCode}
            helperText={errors?.zipCode?.message}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name={formField('phoneNumber')}
            label="Phone"
            type="text"
            variant="outlined"
            defaultValue={facilityValues.phoneNumber || ''}
            inputRef={register()}
            className="text-input"
            error={!!errors.phoneNumber}
            helperText={errors?.phoneNumber?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name={formField('website')}
            label="Facility Website"
            type="text"
            variant="outlined"
            defaultValue={facilityValues.website || ''}
            inputRef={register()}
            className="text-input"
            error={!!errors.website}
            helperText={errors?.website?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={formField('isPrivate')}
            render={({ onChange, onBlur, name, ref }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={e => onChange(e.target.checked)}
                    color="secondary"
                  />
                }
                label="This is a private facility"
                style={{ userSelect: 'none' }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  )

  // render a display-only version of the facility fields if
  // a pre-existing facility is selected
  const facilityDisplay = (
    <FacilityDisplay
      display={isFacilitySelected ? 'block' : 'none'}
      values={facilityValues}
      clearPGAFacilitySelection={clearPGAFacilitySelection}
    />
  )

  return (
    <Box>
      {title}
      {facilityDisplay}
      {facilityFormFields}
      {facilityValues.isPrivate && (
        <Controller
          control={control}
          name={formField('customData.offersCoachingAtPrivateClub')}
          render={({ onChange, onBlur, value, name, ref }) => (
            <FormControlLabel
              control={
                <Checkbox
                  id={`checkbox-public-coaching-${facilityValues.facilityRank}`}
                  name={name}
                  onBlur={onBlur}
                  checked={value}
                  inputRef={ref}
                  onChange={e => onChange(e.target.checked)}
                  color="secondary"
                />
              }
              label="I offer coaching to the public at this facility"
              htmlFor={`checkbox-public-coaching-${facilityValues.facilityRank}`}
              style={{ userSelect: 'none' }}
            />
          )}
        />
      )}
      <Box display="none">
        <SectionTitle>Description</SectionTitle>
        <HelperMsg>Add your own description for this facility.</HelperMsg>
        <Controller
          name={formField('customData.description')}
          control={control}
          render={({ onChange, onBlur, value }, _) => (
            <TextFieldCounter
              characterLimit={1000}
              label="Facility Description"
              multiline
              rows={4}
              name={formField('customData.description')}
              onChange={onChange}
              onBlur={onBlur}
              type="text"
              variant="outlined"
              value={value}
              style={{ marginTop: '1rem' }}
              className={`multiline ${errors.customData &&
                errors.customData.description &&
                ' error'}`}
            />
          )}
        />
        {/* Photos Sub-Section */}
        <SectionTitle style={{ marginTop: '1rem' }}>Photos</SectionTitle>
        <HelperMsg>{photosMsg}</HelperMsg>
        <Controller
          name={formField('customData.photos[0]')}
          control={control}
          render={({ onChange, value }, _) => (
            <>
              {value ? (
                <FacilityImage>
                  <img src={value} alt="Facility" />
                </FacilityImage>
              ) : null}
              <Box className={styles.flexRow} style={{ margin: '1rem 0 2rem' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  type="button"
                  onClick={() =>
                    handleImageUpload(
                      uploadOptions,
                      setFieldValue,
                      setUploadError,
                      `${formField('customData')}.photos`,
                      [],
                    )
                  }
                >
                  Upload Photo
                </Button>
                <ErrorMsg>{uploadError}</ErrorMsg>
                {value ? (
                  <Button
                    variant="text"
                    color="primary"
                    size="large"
                    type="button"
                    onClick={() => {
                      setFieldValue(formField('customData.photos[0]'), null)
                    }}
                  >
                    Delete
                  </Button>
                ) : null}
              </Box>
            </>
          )}
        />
      </Box>
    </Box>
  )
}

CoachProfileEditFacilities.propTypes = {
  formPrefix: PropTypes.string,
  isPrimary: PropTypes.bool,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  facilityIndex: PropTypes.number,
}

CoachProfileEditFacilities.defaultProps = {
  errors: {},
}

export default CoachProfileEditFacilities
