import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Box, Chip } from '@material-ui/core'
import { formattedTimeWithTimezone } from 'utils/dateUtils'
import pluralize from 'lib/pluralize'
import ButtonCta from 'components/atoms/button-cta'
import { IconFlagFilled, IconPrice, IconCalendar } from 'components/atoms/icons'
import useStyles, { CardIcon } from './group-event-card.styles'
import { centsToDollarsFormatted } from 'lib/utils/number'
import { gtmEvent } from 'lib/gtm'
import { PGA_COM_HOST } from 'env'
import EventDetailsModal from './event-details-modal'
import Button from '@material-ui/core/Button'
import CtaArrow from '../atoms/cta-arrow'
import { DateTime } from 'luxon'
import { useFeatureFlags, flags } from 'lib/feature-flags'
import { composeOptimizedImageUrl } from 'lib/utils'

const GroupEventCard = ({ event, showEventDetails, coach }) => {
  const classes = useStyles({ showEventDetails })
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const {
    title,
    startDateTime,
    endDateTime,
    slug,
    maxAttendance,
    registrationCount,
    featuredImageUrl,
    priceInCents,
    sessions,
    sessionsTitle,
    coachFacility,
    hasWaitlist,
    tags,
  } = event

  const timePeriod = formattedTimeWithTimezone(
    startDateTime,
    endDateTime,
    coachFacility?.timezone,
  )
  const priceInDollars = centsToDollarsFormatted(priceInCents)
  const remainingSpots = maxAttendance - registrationCount
  const showWaitlistChip = hasWaitlist && remainingSpots === 0
  const showLimitedAvailabilityChip =
    maxAttendance !== null && remainingSpots <= 5 && !showWaitlistChip

  const handleClick = (slug, showEventDetails) => {
    if (showEventDetails) {
      return setIsDetailsOpen(!isDetailsOpen)
    } else {
      gtmEvent({
        event: 'click-view-event',
        title: title,
        category: tags,
        facility: coachFacility?.name,
        price: priceInDollars,
        event_date: DateTime.fromISO(startDateTime, {
          zone: coachFacility?.timezone,
        }).toFormat('yyyy-LL-dd'),
        event_time: DateTime.fromISO(startDateTime, {
          zone: coachFacility?.timezone,
        }).toFormat('hh:mm a'),
        coach_id: coach?.id,
        coach_name: coach?.name,
      })
      // Remove the below event in the future.
      gtmEvent({
        event: 'formSubmit',
        formCategory: 'group-event-offering',
        formAction: 'group-event-viewed',
        formStatus: 'coach-offerings',
      })
      window.location.href = `${PGA_COM_HOST}/things-to-do/events/${slug}`
    }
  }
  const [isAgeSpecificGroupEventsEnabled] = useFeatureFlags([
    flags.FLAG_FEAT_AGE_SPECIFIC_GROUP_EVENTS,
  ])
  let ageSpecificLabel = null
  if (tags?.includes('Adults Only') || tags?.includes('Juniors Only')) {
    ageSpecificLabel = tags?.includes('Adults Only')
      ? 'Adults Only'
      : 'Juniors Only'
  }

  return (
    <Card
      className={`${classes.groupEvent}`}
      onClick={() => handleClick(slug, showEventDetails)}
    >
      <CardMedia
        className={`${classes.media}`}
        image={
          featuredImageUrl && composeOptimizedImageUrl(featuredImageUrl, 600)
        }
        title={title}
      />

      <CardIcon />
      <Box className={classes.chipContainer}>
        {showLimitedAvailabilityChip && (
          <Chip className={classes.chip} label="Limited Availability" />
        )}
        {isAgeSpecificGroupEventsEnabled && ageSpecificLabel && (
          <Chip className={classes.chip} label={ageSpecificLabel} />
        )}
        {showWaitlistChip && <Chip className={classes.chip} label="Waitlist" />}
      </Box>
      <Box style={{ flexGrow: '1', display: 'flex', flexDirection: 'column' }}>
        <CardContent className={classes.cardContent}>
          <Box>
            {startDateTime && endDateTime && (
              <Typography variant="caption" className={classes.caption}>
                {timePeriod}
              </Typography>
            )}
            <Typography
              variant="h6"
              color="primary"
              className={classes.cardSubtitle}
            >
              {title}
            </Typography>
          </Box>
          <Box>
            {coachFacility != null && (
              <Box display="flex" mb={1}>
                <IconFlagFilled />
                <Typography
                  variant="caption"
                  className={classes.caption}
                  style={{ marginLeft: '12px' }}
                >
                  {coachFacility.name}
                </Typography>
              </Box>
            )}
            {sessions?.length > 0 && (
              <Box display="flex" mb={1}>
                <IconCalendar />
                <Typography
                  variant="caption"
                  className={classes.caption}
                  style={{ marginLeft: '12px' }}
                >
                  {sessionsTitle ||
                    `${sessions.length} ${pluralize(
                      sessions.length,
                      'session',
                    )}`}
                </Typography>
              </Box>
            )}
            {priceInCents != null && (
              <Box display="flex">
                <IconPrice />
                <Typography
                  variant="caption"
                  className={classes.caption}
                  style={{ marginLeft: '12px' }}
                >
                  {priceInDollars} / participant
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Box>
      {showEventDetails ? (
        <Button
          color="primary"
          className={classes.ctaButton}
          onClick={() => setIsDetailsOpen(true)}
        >
          Learn More
          <CtaArrow />
        </Button>
      ) : (
        <ButtonCta
          variant="text"
          arrow
          href={`${PGA_COM_HOST}/things-to-do/events/${slug}`}
          className={classes.ctaButton}
        >
          View Event
        </ButtonCta>
      )}
      <EventDetailsModal
        isOpen={isDetailsOpen}
        event={event}
        setIsOpen={setIsDetailsOpen}
      />
    </Card>
  )
}

GroupEventCard.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    featuredImageUrl: PropTypes.string,
    startDateTime: PropTypes.string,
    endDateTime: PropTypes.string,
    maxAttendance: PropTypes.number,
    slug: PropTypes.string,
    registrationCount: PropTypes.number,
    priceInCents: PropTypes.number,
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    sessionsTitle: PropTypes.string,
    coachFacility: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      timezone: PropTypes.string,
    }),
    hasWaitlist: PropTypes.bool,
  }).isRequired,
  showEventDetails: PropTypes.bool,
}

export default GroupEventCard
