import React, { useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import CheckIcon from '@mui/icons-material/Check'
import { styled } from '@material-ui/core/styles'

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: '#00234B !important',
  border: '1px solid #70787C !important',
  height: '30px',
  '&.Mui-selected': {
    backgroundColor: '#EBF0F2 !important',
  },
}))

const YesNoToggle = ({ index, register, graduatedStatus }) => {
  const [response, setResponse] = useState(graduatedStatus)

  const handleResponse = (event, newResponse) => {
    if (newResponse !== null) {
      setResponse(newResponse)
    }
  }

  return (
    <ToggleButtonGroup
      value={response}
      exclusive
      onChange={handleResponse}
      aria-label="response"
    >
      <input
        type="hidden"
        value={response}
        name={`students.${index}.status`}
        ref={register()}
      />
      <StyledToggleButton value="PASSED" aria-label="Yes">
        {response === 'PASSED' && <CheckIcon fontSize="small" />}
        &nbsp;Yes
      </StyledToggleButton>
      <StyledToggleButton value="FAILED" aria-label="No">
        {response === 'FAILED' && <CheckIcon fontSize="small" />}
        &nbsp;No
      </StyledToggleButton>
    </ToggleButtonGroup>
  )
}

export default YesNoToggle
