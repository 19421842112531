import { APP_ENVIRONMENT } from 'env'
import { useEffect, useState } from 'react'
import { gtmEvent } from 'lib/gtm'

export const secureLink = link =>
  link ? link.replace('http:', 'https:') : link

export const downloadQR = () => {
  const canvas = document.getElementById('coach-profile-qr-code')
  const pngUrl = canvas
    .toDataURL('image/png')
    .replace('image/png', 'image/octet-stream')
  const downloadLink = document.createElement('a')
  downloadLink.href = pngUrl
  downloadLink.download = 'qr-code.png'
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

export const isPgaLink = url => {
  const isDev = APP_ENVIRONMENT === 'development'
  const bookingUrl = new URL(url)

  if (isDev) {
    return (
      bookingUrl.hostname &&
      (/localhost/i.test(bookingUrl.hostname) ||
        /\.pga\.com/i.test(bookingUrl.hostname))
    )
  }

  return bookingUrl.hostname && /\.pga\.com/i.test(bookingUrl.hostname)
}
/**
 * Use case is to check to see if the element has been scrolled into the viewport and not simply whether the user has reached the page.
 * if the element is "above the fold" this will return true so be aware of the limitation
 * A custom hook using the Intersection Observer API to determine
 * whether a given element (provided as a ref) is currently in the viewport.
 *
 * @param {Object} ref - A React ref object pointing to the element to observe.
 * @param {Object} options - An options object for the Intersection Observer.
 * @returns {boolean} - A state value indicating whether the element is currently intersecting the viewport.
 */
export const useIntersectionObserver = (ref, options) => {
  // State to store whether the observed element is intersecting the viewport
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    // Create a new Intersection Observer with a callback that updates our state
    // Docs here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    const observer = new IntersectionObserver(entries => {
      try {
        if (entries[0]) {
          setIntersecting(entries[0].isIntersecting)
        } else {
          setIntersecting(false)
        }
      } catch (error) {
        console.error('ResizeObserver error:', error)
      }
    }, options)

    const currentRef = ref.current || null
    if (currentRef) {
      if (document.readyState === 'complete') {
        // If the document has finished loading, start observing the element
        observer.observe(currentRef)
      } else {
        // If the document is still loading, wait until it's done to start observing
        window.addEventListener('load', () => observer.observe(currentRef))
      }
    }

    return () => {
      // On cleanup, stop observing the element
      if (currentRef) {
        observer.unobserve(currentRef)
        observer.disconnect()
      }
    }
  }, [ref, options])

  return isIntersecting
}

// Handlers for refetching/tracking Session Modal information.
export const onReschedule = refresh => {
  gtmEvent({
    event: 'formSubmit',
    formCategory: 'bookings',
    formAction: 'reschedule',
    formStatus: 'success',
  })
  refresh()
}

export const onPayment = refresh => {
  gtmEvent({
    event: 'formSubmit',
    formCategory: 'bookings',
    formAction: 'record-payment',
    formStatus: 'success',
  })
  refresh()
}

export const onCancellation = refresh => {
  gtmEvent({
    event: 'formSubmit',
    formCategory: 'bookings',
    formAction: 'cancel-booking',
    formStatus: 'success',
  })
  refresh()
}

export const composeOptimizedImageUrl = (url, width) => {
  if (!url) return ''
  if (!width) return url

  // do not optimize image loading with existing height query
  if (url.includes('?h=') || url.includes('&h=') || url.includes('/h_')) {
    return url
  }

  const allowedHosts = [
    'images.ctfassets.net',
    'images.unsplash.com',
    'res.cloudinary.com',
  ]
  const parsedUrl = new URL(url)
  const { host } = parsedUrl

  if (allowedHosts.includes(host)) {
    if (host === 'res.cloudinary.com' && url.includes('/pgahq/image/upload')) {
      const [part1, part2] = url.split('upload')
      return `${part1}upload/w_${width}${part2}`
    }
    return `${url}&w=${width}`
  }
  return url
}
