import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  backButtonWrapper: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    margin: '15px 0',
  },
  titleFont: {
    fontSize: '16px',
    color: theme.palette.text.secondary,
    fontWeight: 900,
  },
  headerFont: {
    fontSize: '30px',
    fontWeight: 900,
  },
  avatarWrapper: {
    marginRight: '15px',
  },
  studentContent: {
    margin: '15px 0',
  },
  exportSelect: {
    '& #export': {
      minWidth: '100px !important',
      padding: '10px 14px !important',
      [theme.breakpoints.down('xs')]: {
        padding: '10px 0 !important',
      },
    },
    '& label': {
      transform: 'translate(14px, 12px) scale(1)',
      textTransform: 'uppercase',
      color: theme.palette.primary.light,
      fontSize: '13px',
      fontWeight: 500,
      fontFamily: 'Roboto',
    },
    '& a': {
      padding: '0px 12px 0px 0px',
    },
    '& svg': {
      color: theme.palette.primary.light,
    },
  },
  exportButton: {
    fontSize: '13px',
    fontWeight: 500,
    fontFamily: 'Roboto',
  },
}))
