import React, { useState, useEffect } from 'react'
import ExternalLinkButton from 'components/external-link-button'
import {
  GET_UPCOMING_GROUP_EVENTS,
  GET_PAST_GROUP_EVENTS,
} from 'pages/pga-coach/bookings/events/query'
import { LIGHTSPEED_VT_URL } from 'utils/constants'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'
import { get } from 'lodash'
import { DateTime } from 'luxon'
import { useQuery } from '@apollo/client'
import { useHistory, Link } from 'react-router-dom'
import Disclaimer from 'components/disclaimer'
import { parseEvents } from 'utils/eventUtils'
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined'
import {
  ADMTrainingExternalLink,
  GROUP_EVENT_PAGE_LIMIT,
} from 'lib/pga-dot-coach'
import { red } from '@material-ui/core/colors'
import useIsMobile from 'lib/hooks/use-is-mobile'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import EventsDisplaySelection from './events-display-selection'
import { RefetchContext } from 'lib/utils/refetchContext'

const EventsPageData = ({ selectedTab, showCanceledEvents }) => {
  const [events, setEvents] = useState([])
  const [offset, setOffset] = useState(0)
  const [lastPage, setLastPage] = useState(false)

  const history = useHistory()
  const isMobile = useIsMobile()
  const goToTrainingLink = LIGHTSPEED_VT_URL
  const currentPage = offset / GROUP_EVENT_PAGE_LIMIT + 1

  useEffect(() => {
    setEvents([])
    setOffset(0)
    setLastPage(false)
  }, [selectedTab, showCanceledEvents])

  const showPastEvents = selectedTab === 'past'
  const startDateTime = showPastEvents
    ? {
      from: null,
      to: DateTime.local()
        .startOf('day')
        .toISO(),
    }
    : {
      from: DateTime.now()
        .startOf('day')
        .toISO(),
      to: null,
    }
  const passEventsToState = eventsData => {
    if (eventsData) {
      const newEvents = parseEvents(
        get(eventsData, 'currentUser.groupEvents', []),
      )
      setEvents(newEvents)
      if (newEvents.length < GROUP_EVENT_PAGE_LIMIT && offset > 0) {
        setLastPage(true)
      }
    }
  }

  const retreatPagination = async () => {
    const newOffset =
      offset - GROUP_EVENT_PAGE_LIMIT < 0 ? 0 : offset - GROUP_EVENT_PAGE_LIMIT
    setOffset(newOffset)
    await refetch({ offset: newOffset })
    setLastPage(false)
  }
  const advancePagination = async () => {
    const newOffset = offset + GROUP_EVENT_PAGE_LIMIT
    setOffset(newOffset)
    await refetch({ offset: newOffset })
  }

  const pastEventsVariables = {
    orderBy: { field: 'START_DATE_TIME', direction: 'DESC' },
    startDateTime: startDateTime,
    includeCanceled: showCanceledEvents,
    limit: GROUP_EVENT_PAGE_LIMIT,
    offset: offset,
    skip: selectedTab !== 'past',
  }

  const upcomingEventsVariables = {
    orderBy: { field: 'START_DATE_TIME', direction: 'ASC' },
    startDateTime: startDateTime,
    includeCanceled: showCanceledEvents,
    limit: GROUP_EVENT_PAGE_LIMIT,
    offset: offset,
    skip: selectedTab !== 'upcoming',
  }

  const useGroupEventsQuery = (query, variables, skip) => {
    return useQuery(query, {
      variables: variables,
      skip: skip,
    })
  }

  const pastEventsResult = useGroupEventsQuery(
    GET_PAST_GROUP_EVENTS,
    pastEventsVariables,
    selectedTab !== 'past',
  )
  const upcomingEventsResult = useGroupEventsQuery(
    GET_UPCOMING_GROUP_EVENTS,
    upcomingEventsVariables,
    selectedTab !== 'upcoming',
  )

  const queryResult =
    selectedTab === 'past' ? pastEventsResult : upcomingEventsResult

  const { data, loading, error, refetch } = queryResult

  useEffect(() => {
    passEventsToState(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, showCanceledEvents, selectedTab])

  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error />

  const { canPromoteContent } = data.currentUser.attributes

  return (
    <>
      {!canPromoteContent ? (
        <Container maxWidth="md">
          <Box mt={4} p={2} borderRadius={4} bgcolor={red[50]}>
            <Typography variant="body2" align="center">
              {
                'You are currently ineligible to promote your events on PGA.com. Please complete '
              }
              <ADMTrainingExternalLink />
              {' to become eligible.'}
            </Typography>
          </Box>
        </Container>
      ) : (
        <Container maxWidth="md">
          <Box mt={3}>
            <Disclaimer
              icon={<SchoolOutlinedIcon fontSize="large" />}
              description={
                <>
                  <strong>
                    {'Need help getting started with group coaching? '}
                  </strong>
                  {'Review our course on Building Coaching Programs'}
                </>
              }
              action={
                <ExternalLinkButton
                  variant={isMobile ? 'text' : 'outlined'}
                  color="primary"
                  component={Link}
                  href={goToTrainingLink}
                >
                  View Training
                </ExternalLinkButton>
              }
            />
          </Box>
        </Container>
      )}
      <RefetchContext.Provider value={refetch}>
        <EventsDisplaySelection
          events={events}
          history={history}
          selectedTab={selectedTab}
          advancePagination={advancePagination}
          retreatPagination={retreatPagination}
          currentPage={currentPage}
          lastPage={lastPage}
        />
      </RefetchContext.Provider>
    </>
  )
}
export default EventsPageData
