const GroupEventFragment = `
    slug
    tags
    featuredImageUrl
    priceInCents
    totalPriceInCents
    acceptsOnlinePayments
    paymentRecurrenceSchedule
    numberOfPayments
    lessonCount
    registrationCloseDateTime
    externalRegistrationUrl
    registrationCount
    collectAttendance
    sessions {
      id
      startDateTime
      endDateTime
      enrollments {
        id
        student {
          id
          firstName
          lastName
        }
        attended
        isCanceled
      }
    }
    assistantCoaches {
      id
      name
    }
    waitlist {
      entries {
        contact {
          id
          firstName
          lastName
          email
        }
      }
    }
    enrollments {
      rsvpStatus
    }
`
export default GroupEventFragment
