import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Card from '@material-ui/core/Card'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import { parseEvent } from 'utils/eventUtils'
import {
  getTimePeriodWithTimezone,
  getDateTimePeriodWithTimezone,
} from 'utils/dateUtils'
import EventDetailsDialog from '../events/event-details-dialog'
import Hidden from '@material-ui/core/Hidden'
import useIconAccessor from 'components/lesson-type-category/icons'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined'
import { composeOptimizedImageUrl } from 'lib/utils'

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    marginBottom: '20px',
    padding: '28px 0 28px 0',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
    },
  },
  featuredImgWrapper: {
    margin: '0 20px 0 24px',
  },
  bodyText: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px',
    },
    color: '#0000008a',
  },
  avatarImg: {
    width: '65px',
    height: '65px',
    color: 'rgba(0, 35, 75, 1)',
    backgroundColor: 'rgba(244, 246, 248, 1)',
    [theme.breakpoints.down('xs')]: {
      width: '50px',
      height: '50px',
    },
  },
  timePeriodText: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '16px',
    },
    color: '#000000de',
  },
  nextIcon: {
    color: '#0000008a',
    paddingRight: '1rem',
  },
  groupEventChip: {
    backgroundColor: '#DBD2C1',
    color: '#000000de',
  },
  defaultChip: {
    backgroundColor: '#e0e0e0',
    color: '#000000de',
  },
}))

const AssistantCoaches = ({ coach, assistantCoaches }) => {
  const classes = useStyles()

  if (!assistantCoaches || assistantCoaches.length === 0) return null

  const names = assistantCoaches.map(coach => coach.name).join(', ')

  return (
    <ListItem style={{ padding: '0.75rem 0 0 0' }}>
      <ListItemIcon>
        <PeopleOutlineOutlinedIcon style={{ color: '#836F50' }} />
      </ListItemIcon>
      <ListItemText
        disableTypography
        className={classes.bodyText}
        primary={`${coach.name}, ${names}`}
      />
    </ListItem>
  )
}

const GroupEventListItem = ({ groupEvent, withDate = false }) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)

  const {
    startDateTime,
    endDateTime,
    title,
    coachFacility,
    timezone,
    isCanceled,
    assistantCoaches,
  } = groupEvent

  const timePeriod = withDate
    ? getDateTimePeriodWithTimezone(startDateTime, endDateTime, timezone)
    : getTimePeriodWithTimezone(startDateTime, endDateTime, timezone)

  const classes = useStyles()
  const iconAccessor = useIconAccessor()
  const { icon } = iconAccessor.GROUP

  return (
    <Card className={classes.root} variant="outlined">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setIsDetailsOpen('detail')}
      >
        <Box display="flex" alignItems="center">
          <Box className={classes.featuredImgWrapper}>
            <Avatar
              className={classes.avatarImg}
              src={composeOptimizedImageUrl(groupEvent.featuredImageUrl, 800)}
              children={icon}
              alt="Group Event Featured Image"
            />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="h6" className={classes.timePeriodText}>
              {timePeriod}
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              {coachFacility.name}
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              {title}
            </Typography>
            <AssistantCoaches
              coach={groupEvent.coach}
              assistantCoaches={assistantCoaches}
            />
          </Box>
        </Box>
        <Box display="flex">
          <Hidden smDown>
            <Box display="flex" flexDirection="row-reverse" alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                style={{ marginRight: '10px' }}
              >
                <Chip className={classes.groupEventChip} label="Group Event" />
                {isCanceled && (
                  <Chip
                    className={classes.defaultChip}
                    style={{ marginLeft: '12px' }}
                    label="Canceled"
                  />
                )}
                {groupEvent.externalRegistrationUrl ? (
                  <Chip
                    className={classes.defaultChip}
                    style={{ marginLeft: '12px' }}
                    label="External Registration"
                  />
                ) : null}
              </Box>
            </Box>
          </Hidden>
          <NavigateNextIcon className={classes.nextIcon} fontSize="large" />
        </Box>
      </Box>
      <Hidden mdUp>
        <Box
          display="flex"
          flexDirection="row-reverse"
          alignItems="center"
          justifyContent="start"
          style={{ paddingLeft: '98px', paddingTop: '12px' }}
        >
          <Box
            display="flex"
            alignItems="center"
            style={{ marginRight: '10px' }}
          >
            <Chip
              size="small"
              label="Group Event"
              className={classes.groupEventChip}
            />
            {isCanceled && (
              <Chip
                className={classes.defaultChip}
                style={{ marginLeft: '12px' }}
                size="small"
                label="Canceled"
              />
            )}
            {groupEvent.externalRegistrationUrl ? (
              <Chip
                className={classes.defaultChip}
                style={{ marginLeft: '5px' }}
                size="small"
                label="External Registration"
              />
            ) : null}
          </Box>
        </Box>
      </Hidden>
      <EventDetailsDialog
        isOpen={isDetailsOpen}
        setIsOpen={setIsDetailsOpen}
        event={parseEvent(groupEvent)}
      />
    </Card>
  )
}

export default GroupEventListItem
