import React, { useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import CheckIcon from '@mui/icons-material/Check'
import { styled } from '@material-ui/core/styles'

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: '#00234B !important',
  height: '30px',
  '&.Mui-selected': {
    backgroundColor: '#EBF0F2 !important',
  },
}))

const YesNoToggle = ({ attended, index, register }) => {
  const [response, setResponse] = useState(
    attended === true || attended === null,
  )

  const handleResponse = (event, newResponse) => {
    if (newResponse !== null) {
      setResponse(newResponse)
    }
  }

  return (
    <ToggleButtonGroup
      value={response}
      exclusive
      onChange={handleResponse}
      aria-label="response"
    >
      <input
        type="hidden"
        value={response}
        name={`enrollments.${index}.attended`}
        ref={register()}
      />
      <StyledToggleButton value={true} aria-label="Yes">
        {response === true && <CheckIcon fontSize="small" />}
        &nbsp;Yes
      </StyledToggleButton>
      <StyledToggleButton value={false} aria-label="No">
        {response === false && <CheckIcon fontSize="small" />}
        &nbsp;No
      </StyledToggleButton>
    </ToggleButtonGroup>
  )
}

export default YesNoToggle
