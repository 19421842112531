import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import YesNoToggle from './yes-no-toggle'
import { getInitials } from 'lib/text-display'

const StudentRow = ({ student, sessions, register, index, eventId }) => {
  const totalSessions = sessions.length
  const attendedSessions = sessions.filter(
    session =>
      session.enrollments.filter(
        enrollment =>
          enrollment.student.id === student.id && enrollment.attended,
      ).length > 0,
  ).length

  const graduatedStatus = student.graduations[0]?.status || 'IN_PROGRESS'

  const studentName = `${student.firstName} ${student.lastName}`
  const studentInitials = getInitials(studentName)
  return (
    <Box
      mt={3}
      key={student.id}
      display="flex"
      alignItems="center"
      mb={2}
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center">
        <Avatar>{studentInitials}</Avatar>
        <Box display="flex" flexDirection="column" ml={1.5}>
          <Typography variant="body1">{studentName}</Typography>
          <Typography variant="body2">
            {`${attendedSessions}/${totalSessions}`} sessions attended
          </Typography>
        </Box>
      </Box>
      <YesNoToggle
        index={index}
        register={register}
        graduatedStatus={graduatedStatus}
      />
      <input
        type="hidden"
        value={student.id}
        name={`students.${index}.id`}
        ref={register()}
      />
    </Box>
  )
}

export default StudentRow
