import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import { DateTime } from 'luxon'
import pluralize from 'lib/pluralize'
import { composeOptimizedImageUrl } from 'lib/utils'

const useStyles = makeStyles(theme => ({
  featuredImage: {
    backgroundImage: ({ featuredImageUrl }) =>
      `url("${composeOptimizedImageUrl(featuredImageUrl, 600)}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '160px',
    width: '160px',
    borderRadius: '4px',
    flexShrink: 0,
    [theme.breakpoints.only('xs')]: {
      height: '100px',
      width: '100px',
    },
  },
  cardTitle: {
    fontWeight: 700,
    marginBottom: '16px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '22px',
    },
  },
  eventTitle: {
    lineHeight: '22px',
    fontWeight: 700,
    [theme.breakpoints.only('xs')]: {
      fontWeight: 600,
    },
  },
  eventDetail: {
    lineHeight: '22px',
    color: theme.palette.text.primary,
  },
  messageLink: {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '14px',
  },
  hideOnMobile: {
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
}))

const getEndDateTimeWithSessions = sessions => {
  if (sessions.length < 2) return null
  const orderedByDateSessions = sessions.sort((a, b) => {
    return new Date(a.startDateTime) - new Date(b.startDateTime)
  })
  const lastSession = orderedByDateSessions[sessions.length - 1]
  return lastSession.endDateTime
}

const EventInfo = ({ groupEvent, hideMessageLink = true }) => {
  const {
    maxAttendance,
    registrationCount,
    featuredImageUrl,
    isCanceled,
    registrationClosed,
    registrationFull,
    slug,
    timezone,
    coachFacility,
    title,
    startDateTime,
    endDateTime,
    sessions = [],
  } = groupEvent

  const isRegistrationEnabled = !(
    isCanceled ||
    registrationClosed ||
    registrationFull
  )

  const spotsRemaining =
    maxAttendance != null && maxAttendance - registrationCount

  const shouldDisplaySpotsRemaining =
    isRegistrationEnabled &&
    spotsRemaining &&
    spotsRemaining > 0 &&
    spotsRemaining <= 10

  const classes = useStyles({ featuredImageUrl })

  const hasFullAddress =
    coachFacility.street && coachFacility.city && coachFacility.zipCode

  const endDateTimeWithSessions = getEndDateTimeWithSessions(sessions)

  return (
    <>
      <Typography variant="h4" component="h1" className={classes.cardTitle}>
        Event Details
      </Typography>
      <Box display="flex" alignItems="center">
        <Box className={classes.featuredImage} />
        <Box pl={3} display="flex" flexDirection="column">
          <Typography variant="body1" className={classes.eventTitle}>
            {title}
          </Typography>
          <Typography variant="body1" className={classes.eventDetail}>
            {coachFacility.name}
          </Typography>
          <Box pb={1} className={classes.hideOnMobile}>
            {hasFullAddress && (
              <Typography variant="body1" className={classes.eventDetail}>
                {coachFacility.street}, {coachFacility.city},{' '}
                {coachFacility.zipCode}
              </Typography>
            )}
          </Box>
          <Typography variant="body1" className={classes.eventDetail}>
            Starts{' '}
            {DateTime.fromISO(startDateTime)
              .setZone(timezone)
              .toFormat('MMMM dd, yyyy')}
          </Typography>
          <Typography variant="body1" className={classes.eventDetail}>
            Until{' '}
            {DateTime.fromISO(endDateTimeWithSessions || endDateTime)
              .setZone(timezone)
              .toFormat('MMMM dd, yyyy')}
          </Typography>
          <Typography variant="body1" className={classes.eventDetail}>
            {DateTime.fromISO(startDateTime)
              .setZone(timezone)
              .toFormat('h:mm')}{' '}
            –{' '}
            {DateTime.fromISO(endDateTime)
              .setZone(timezone)
              .toFormat('h:mm a (ZZZZ)')}
          </Typography>
          {!hideMessageLink && (
            <Link
              variant="body1"
              TypographyClasses={{ root: classes.messageLink }}
              component={RouterLink}
              to={`/things-to-do/events/${slug}/messages`}
            >
              Message Event Host
            </Link>
          )}
          <Box display={shouldDisplaySpotsRemaining ? 'block' : 'none'}>
            <Typography
              variant="caption"
              color="error"
              style={{ fontWeight: 700 }}
            >
              {spotsRemaining} {pluralize(spotsRemaining, 'spot')} remaining
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default EventInfo
