export const dashboard = () => '/junior-league'

export const locations = () => '/junior-league/locations'

export const signup = () => '/junior-league-signup'

export const signupLpga = () => `${signup()}/lpga`

export const signupLpgaRequest = () => `${signup()}/lpga-request`

export const location = locationId => `/junior-league/locations/${locationId}`

export const locationPath = (locationId, path) =>
  `${location(locationId)}/${path}`

export const newLocation = () => '/junior-league/locations/new'

export const programs = locationId =>
  `/junior-league/locations/${locationId}/programs`

export const newProgram = locationId =>
  `/junior-league/locations/${locationId}/programs/new`

export const newRegionalChampionshipProgram = locationId =>
  `${newProgram(locationId)}/regional-championship-program`

export const newNationalChampionshipProgram = locationId =>
  `${newProgram(locationId)}/national-championship-program`

export const newChampProgram = locationId =>
  `${newProgram(locationId)}/championship-season`

export const program = (locationId, programId, anchor = '') =>
  `${location(locationId)}/programs/${programId}${anchor}`

export const regionalChampionshipProgram = (
  locationId,
  programId,
  anchor = '',
) =>
  `${location(locationId)}/regional-championship-program/${programId}${anchor}`

export const regionalProgramSchedule = (locationId, programId) =>
  `${regionalChampionshipProgram(locationId, programId)}/schedule`

export const nationalChampionshipProgram = (
  locationId,
  programId,
  anchor = '',
) =>
  `${location(locationId)}/national-championship-program/${programId}${anchor}`

export const nationalProgramSchedule = (locationId, programId) =>
  `${nationalChampionshipProgram(locationId, programId)}/schedule`

export const newRegionalEvent = (locationId, programId, eventType) =>
  `${regionalChampionshipProgram(
    locationId,
    programId,
  )}/schedule/events/new?eventType=${eventType}`

export const editRegionalEvent = (locationId, programId, eventId) =>
  `${regionalChampionshipProgram(
    locationId,
    programId,
  )}/schedule/events/${eventId}`

export const newNationalEvent = (locationId, programId, eventType) =>
  `${nationalChampionshipProgram(
    locationId,
    programId,
  )}/schedule/events/new?eventType=${eventType}`

export const editNationalEvent = (locationId, programId, eventId) =>
  `${nationalChampionshipProgram(
    locationId,
    programId,
  )}/schedule/events/${eventId}`

export const programSchedule = (locationId, programId) =>
  `${program(locationId, programId)}/schedule`

export const programTeams = (locationId, programId) =>
  `${program(locationId, programId)}/teams`

export const programEditTeams = (locationId, programId) =>
  `${programTeams(locationId, programId)}/edit`

export const programScoring = (locationId, programId) =>
  `${program(locationId, programId)}/scoring`

export const programStandings = (locationId, programId) =>
  `${program(locationId, programId)}/standings`

export const programDiscounts = (locationId, programId) =>
  `${program(locationId, programId)}/discount-codes`

export const programPlayers = (locationId, programId) =>
  `${program(locationId, programId)}/players`

export const programFulfillmentOrders = (locationId, programId) =>
  `${program(locationId, programId)}/fulfillment-orders`

export const newProgramFulfillmentOrders = (locationId, programId) =>
  `${programFulfillmentOrders(locationId, programId)}/new`

export const reviewProgramFulfillmentOrder = (
  locationId,
  programId,
  kitOrderId,
) => `${programFulfillmentOrders(locationId, programId)}/${kitOrderId}`

export const editSchedule = (locationId, programId) =>
  `${program(locationId, programId)}/schedule/edit`

export const editEvent = (locationId, programId, eventId, hash) => {
  const hashLink = hash ? `#${hash}` : ''
  return `${program(locationId, programId)}/schedule/edit/${eventId}${hashLink}`
}

export const editMatches = (locationId, programId, eventId) =>
  `${program(locationId, programId)}/schedule/edit/${eventId}/matches`

export const requirements = () =>
  `/account/requirements?return_to=${encodeURIComponent(
    window.location.pathname,
  )}`

export const editScore = (locationId, programId, matchId) =>
  `${program(locationId, programId)}/scoring/edit/${matchId}`

export const marketingEvents = () => '/junior-league-events'

export const leagueMarketing = leagueId =>
  `${marketingEvents()}/leagues/${leagueId}`

export const marketingChampSeasonRegions = () => `${marketingEvents()}/regions`

export const marketingChampSeasonRegion = regionId =>
  `${marketingChampSeasonRegions()}/${regionId}`

export const hostedPlayDays = locationId =>
  `/junior-league/locations/${locationId}/hosted-play-days`

export const newHostedPlayDay = locationId =>
  `${hostedPlayDays(locationId)}/new`

export const hostedPlayDay = (locationId, playDayId) =>
  `${hostedPlayDays(locationId)}/${playDayId}`

const sectionEvents = locationId =>
  `${hostedPlayDays(locationId)}/section-event`

export const newSectionEvent = locationId => `${sectionEvents(locationId)}/new`

export const sectionEvent = (locationId, playDayId) =>
  `${sectionEvents(locationId)}/${playDayId}`

export const hostedPlayDayScoring = (locationId, playDayId) =>
  `${hostedPlayDay(locationId, playDayId)}/scoring`

export const apiHostedPlayDayAttendees = playDayId =>
  `jrl/play_days/${playDayId}/attendees`

export const champProgram = (locationId, programId, anchor = '') =>
  `${location(locationId)}/championship-season/${programId}${anchor}`

export const champProgramSchedule = (locationId, programId) =>
  `${champProgram(locationId, programId)}/schedule`

export const champProgramTeams = (locationId, programId) =>
  `${champProgram(locationId, programId)}/teams`

export const champProgramPlayers = (locationId, programId) =>
  `${champProgram(locationId, programId)}/players`

export const champEditTeams = (locationId, programId) =>
  `${champProgramTeams(locationId, programId)}/edit`

export const champCreateTeam = (locationId, programId) =>
  `${champProgramTeams(locationId, programId)}/create`

export const champEditDivisionTeams = (locationId, programId, division) =>
  `${champEditTeams(locationId, programId)}/${division}`

export const champCreateDivisionTeam = (locationId, programId, division) =>
  `${champCreateTeam(locationId, programId)}/${division}`

export const findPlayDay = (locationId, programId) =>
  `${champProgramSchedule(locationId, programId)}/play-days/search`

export const playDayDetails = (locationId, programId, playDayId) =>
  `${champProgramSchedule(locationId, programId)}/play-days/${playDayId}`

export const newChampEvent = (locationId, programId, eventType) =>
  `${champProgram(
    locationId,
    programId,
  )}/schedule/events/new?eventType=${eventType}`

export const editChampEvent = (locationId, programId, eventId) =>
  `${champProgram(locationId, programId)}/schedule/events/${eventId}`

export const regionalChampionships = locationId =>
  `${location(locationId)}/regional-championships`

export const regionalProgram = (locationId, programId, anchor = '') =>
  `${location(locationId)}/regional-championship-program/${programId}${anchor}`

export const regionalProgramPlayers = (locationId, programId) =>
  `${regionalProgram(locationId, programId)}/players`

export const nationalChampionships = locationId =>
  `${location(locationId)}/national-championships`

export const nationalProgram = (locationId, programId, anchor = '') =>
  `${location(locationId)}/national-championship-program/${programId}${anchor}`

export const nationalProgramPlayers = (locationId, programId) =>
  `${nationalProgram(locationId, programId)}/players`

export const createRegionalChampionship = locationId =>
  `${regionalChampionships(locationId)}/create`

export const createNationalChampionship = locationId =>
  `${nationalChampionships(locationId)}/create`

export const NationalChampionship = (locationId, nationalChampId) =>
  `${nationalChampionships(locationId)}/${nationalChampId}`

export const editNationalChampionship = (locationId, nationalChampId) =>
  `${NationalChampionship(locationId, nationalChampId)}/national-details`

export const RegionalChampionship = (locationId, regionalChampId) =>
  `${regionalChampionships(locationId)}/${regionalChampId}`

export const editRegionalChampionship = (locationId, regionalChampId) =>
  `${RegionalChampionship(locationId, regionalChampId)}/regional-details`

export const regionalChampionshipTeams = (locationId, regionalChampId) =>
  `${location(
    locationId,
  )}/regional-championship-program/${regionalChampId}/teams`

export const editRegionalChampionshipTeam = (
  locationId,
  regionalChampId,
  teamId,
) => `${RegionalChampionship(locationId, regionalChampId)}/teams/${teamId}`

export const playersRegionalChampionship = (locationId, regionalChampId) =>
  `${RegionalChampionship(locationId, regionalChampId)}/players`

export const playersNationalChampionship = (locationId, regionalChampId) =>
  `${NationalChampionship(locationId, regionalChampId)}/players`

export const nationalChampionshipTeams = (locationId, regionalChampId) =>
  `${location(
    locationId,
  )}/national-championship-program/${regionalChampId}/teams`

export const editNationalChampionshipTeam = (
  locationId,
  regionalChampId,
  teamId,
) => `${NationalChampionship(locationId, regionalChampId)}/teams/${teamId}`

export const scoringRegionalChampionship = (locationId, regionalChampId) =>
  `${RegionalChampionship(locationId, regionalChampId)}/scoring`

export const apiProgramPlayerList = programId =>
  `jrl/programs/${programId}/export-player-list`
