import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  backButtonWrapper: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    margin: '15px 0',
  },
  avatarWrapper: {
    marginRight: '15px',
  },
  studentContent: {
    margin: '15px 0',
  },
}))
