import React from 'react'
import { useAuth } from 'lib/auth'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import CoachEventDetails from './event-details-modal/coach-event-details'
import ConsumerEventDetails from './event-details-modal/consumer-event-details'
import EventParticipants from './event-participants'
import EventStats from './event-stats'

const WhichDetailsModal = props => {
  const { isConsumer } = useAuth()

  return isConsumer ? (
    <ConsumerEventDetails {...props} />
  ) : (
    <CoachEventDetails {...props} />
  )
}

const EventDetailsDialog = ({ isOpen, setIsOpen, event }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  return (
    <Dialog
      open={!!isOpen}
      onClose={() => setIsOpen(false)}
      fullScreen={isMobile}
      maxWidth={isOpen === 'participants' ? 'md' : 'sm'}
      fullWidth
    >
      {isOpen === 'detail' ? (
        <WhichDetailsModal {...{ setIsOpen, event }} />
      ) : null}
      {isOpen === 'participants' ? (
        <EventParticipants {...{ setIsOpen, event }} />
      ) : null}
      {isOpen === 'stats' ? <EventStats {...{ setIsOpen, event }} /> : null}
    </Dialog>
  )
}

export default EventDetailsDialog
