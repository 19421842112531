import { gql } from '@apollo/client'

export const GET_GROUP_EVENT = gql`
  query GroupEventAndStudentsForRsvpPage(
    $id: ID!
    $lessonId: ID!
    $contactId: ID!
  ) {
    groupEvents(id: $id) {
      id
      slug
      title
      description
      startDateTime
      endDateTime
      coachFacility {
        name
      }
      coach {
        name
        externalId
      }
      featuredImageUrl
      timezone
      tags
    }
    groupEventAttendeesByLesson(lessonId: $lessonId, contactId: $contactId) {
      id
      firstName
      lastName
      enrollments {
        id
        rsvpStatus
      }
    }
  }
`

export const UPDATE_RSVP = gql`
  mutation UpdateEnrollmentRsvps($rsvps: [EnrollmentRsvpInput!]!) {
    updateEnrollmentRsvps(rsvps: $rsvps) {
      success
      message
      enrollments {
        id
        rsvpStatus
      }
    }
  }
`
